
import React, { Component } from 'react'

import { defaults as OlInteraction } from 'ol/interaction'
import OlMap from 'ol/Map'
import OlView from 'ol/View'
import {
    getWidth as getExtentWidth,
    getHeight as getExtentHeight,
} from 'ol/extent'

import OlFeature from 'ol/Feature'
import * as OlGeom from 'ol/geom/'

import OlLayerTile from 'ol/layer/Tile'
import OlLayerVector from 'ol/layer/Vector'

import OlSourceXYZ from 'ol/source/XYZ'
import OlSourceVector from 'ol/source/Vector'

export default class Faerun extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isMounted: false,
            viewCenter: [-5000000, -10000000],
            viewZoom: 3.5,
            viewResolution: 0
        }

        if (OlInteraction) {

            this.layers = {
                tiles: new OlLayerTile({
                    source: new OlSourceXYZ({
                        //url: 'http://localhost:8000/tiles/faerun/{z}/{x}/{y}.png'
                        url: 'https://dndiaries.s3.us-east-2.amazonaws.com/tiles/faerun/{z}/{x}/{y}.png'
                    })
                }),
                markers: new OlLayerVector({
                    source: new OlSourceVector({
                        features: [
                            new OlFeature({
                                geometry: new OlGeom.Point(this.state.viewCenter)
                            })
                        ]
                    })
                })
            }

            this.olmap = new OlMap({
                target: null,
                interactions: OlInteraction({altShiftDragRotate:false, pinchRotate:false}),
                layers: Object.values(this.layers),
                view: new OlView({
                    center: this.state.viewCenter,
                    zoom: this.state.viewZoom
                })
            })

            let extent = this.olmap.getView().getProjection().getExtent()
            this.state.viewCenter = [extent[0] / 2, extent[1] / 2]

        }

    }

    updateMap() {
        if (this.olmap) {
            this.olmap.getView().setCenter(this.state.viewCenter)
            this.olmap.getView().setZoom(this.state.viewZoom)
        }
    }

    componentDidMount() {
        if (this.olmap) {

            this.olmap.setTarget('map')

            var extent = this.olmap.getView().getProjection().getExtent()
            this.setState({ extent })

            var size = this.olmap.getSize()
            var width = getExtentWidth(extent)
            var height = getExtentHeight(extent)

            if (size[0] / size[1] > width / height) {

                this.olmap.getView().fit([
                    extent[0],
                    (extent[1] + extent[3]) / 2,
                    extent[2],
                    (extent[1] + extent[3]) / 2
                ], {
                    constrainResolution: false
                })

            } else {

                this.olmap.getView().fit([
                    (extent[0] + extent[2]) / 2,
                    extent[1],
                    (extent[0] + extent[2]) / 2,
                    extent[3]
                ], { 
                    constrainResolution: false
                })

            }

            width = this.olmap.getSize()[0] * this.state.viewResolution
            height = this.olmap.getSize()[1] * this.state.viewResolution

            this.addPin(new OlGeom.Point([
                extent[0] + (width / 2),
                extent[1] + (height / 2)
            ]))

            this.onResolution({ extent })

            // Listen to map changes
            this.olmap.on('moveend', () => {
                let center = this.olmap.getView().getCenter()
                let zoom = this.olmap.getView().getZoom()
                this.setState({ center, zoom })
            })

        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (this.olmap) {

            let center = this.olmap.getView().getCenter()
            let zoom = this.olmap.getView().getZoom()
            if (center === nextState.center && zoom === nextState.zoom) return false

        }

        return true

    }

    render() {
        this.updateMap() // Update map on render?

        return (
            <div id='map' style={{ width: '100vw', height: '100vh' }} />
        )
    }

    onResolution = (params) => {

        if (this.olmap) {

            var { resolution, extent } = this.state

            if (!extent && params) {
                extent = params.extent
            }

            var map = this.olmap
            var oldView = map.getView()

            resolution = oldView.getResolution()
            this.setState({ resolution })

            var width = map.getSize()[0] * resolution
            var height = map.getSize()[1] * resolution

            var newView = new OlView({
                projection: oldView.getProjection(),
                extent: [
                    extent[0] + (width / 2),
                    extent[1] + (height / 2),
                    extent[2] - (width / 2),
                    extent[3] - (height / 2)
                ],
                resolution: resolution,
                minZoom: 2.5,
                maxZoom: 6
            })

            this.addPin(new OlGeom.Point([
                extent[0] + (width / 2),
                extent[1] + (height / 2)
            ]))

            newView.setCenter(newView.constrainCenter(oldView.getCenter()))
            newView.on('change:resolution', this.onResolution)
            map.setView(newView)

        }

    }

    addPin = (geometry) => {

        let marker = new OlFeature({
            geometry
        })

        if (marker) {
            this.layers.markers.getSource().addFeature(marker)
        }

    }

}