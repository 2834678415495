
import React from 'react'

import Faerun from 'modules/Faerun'

export default class WarTable extends React.Component {

    render() {
        return <>
            <Faerun />
        </>
    }

}